import "./App.scss";
import Navbar from "./components/navbar/Navbar";
import Home from "./components/home/Home";
import Introduction from "./components/Introduction/Introduction";
import Problematiques from "./components/Problematiques/Problematiques";
import Solutions from "./components/Solutions/Solutions";
import Improvements from "./components/Improvements/Improvements";
import Business from "./components/Business/Business";
import Avantages from "./components/Avantages/Avantages";
import DonneesEtAnalyses from "./components/DonneesEtAnalyses/DonneesEtAnalyses";
import Contact from "./components/contact/Contact";

function App() {
  const realisationId ="Réalisation"
  return (
    <div>
      <section id="Accueil">
        <Navbar />
        <Home />
      </section>
      <section >
        <Introduction />
      </section>
      <section>
        <Problematiques />
      </section>
      <section>
        <Solutions/>
      </section>
      <section>
        <Improvements/>
      </section>
      <section>
        <Business/>
      </section>
      <section>
        <Avantages/>
      </section>
      <section id="Contact">
        <Contact/>
      </section>
    </div>
  );
}

export default App;

