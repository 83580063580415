import React, { useState } from 'react';
import { motion } from 'framer-motion';
import './problematiques.scss';
import { useInView } from 'react-intersection-observer';

const Problematiques = () => {
    const [activeProblem, setActiveProblem] = useState(null);
    const [ref, inView] = useInView({
      triggerOnce: true,
      threshold: 0.2,
    });

    const problems = [
        {
            id: 1,
            title: 'Gestion Désorganisée',
            description: 'Difficultés actuelles dans la gestion des documents et suivis de chantier.',
            details: 'Dans le secteur du BTP, la gestion des documents et des suivis de chantier est souvent chaotique...',
            solutions: ["Centralisation des Documents : 'Vision' propose une solution unique pour stocker et organiser tous les documents de chantier, garantissant un accès facile et une gestion uniforme.",
             "Mise à Jour en Temps Réel : Toutes les modifications ou mises à jour de documents sont immédiatement disponibles pour l'équipe, assurant une communication fluide et cohérente.",
            "Réduction des Risques : Avec des documents bien organisés et facilement accessibles, 'Vision' diminue significativement les risques liés à la perte ou à l'inexactitude des informations."]
        },
        {
          id: 2,
          title: 'Suivi Inefficace',
          description: 'Limitations du suivi quotidien des chantiers.',
          details: 'Le suivi quotidien des chantiers est crucial pour le respect des délais et des budgets...',
          solutions: ["Suivi Intégré : 'Vision' offre une plateforme pour un suivi précis et en temps réel de chaque phase des chantiers.",
          "Alertes et Notifications : Recevez des alertes automatiques en cas de retard ou de problèmes, permettant une réaction rapide et efficace.",
        "Tableaux de Bord Personnalisables : Des tableaux de bord intuitifs pour visualiser l'état d'avancement et les indicateurs clés de performance des chantiers."]
        },
        {
          id: 3,
          title: 'Planification Fragmentée',
          description: 'Enjeux de la planification des tâches et de la gestion des plannings.',
          details: 'La planification et la gestion des tâches dans le BTP sont souvent effectuées en vase clos...',
          solutions: ["Planification Centralisée : 'Vision' permet une planification holistique et intégrée des projets, facilitant la coordination entre les équipes.",
           "Optimisation des Ressources : Gestion intelligente des ressources humaines et matérielles pour maximiser l'efficacité et réduire les coûts.",
          "Analyse Prédictive : Utilisation de données historiques et tendances actuelles pour anticiper les besoins futurs et ajuster les plans en conséquence."]
        },
        {
          id: 4,
          title: 'Communication Améliorée',
          description: "Dans l'industrie du bâtiment, les défis de communication entre différentes parties prenantes (équipes sur le terrain, sous-traitants, clients, etc.) peuvent conduire à des malentendus et des erreurs coûteuses. La coordination efficace est souvent entravée par des canaux de communication fragmentés et inefficaces.",
          details: '',
          solutions: ["Plateforme Unifiée : 'Vision' crée un hub central où toutes les communications et informations relatives au projet sont consolidées. Cela élimine la nécessité de jongler entre plusieurs outils et plateformes, assurant une source unique et fiable pour toutes les communications.",
        "Mises à Jour en Temps Réel : Les informations sur le projet sont mises à jour en temps réel et sont immédiatement accessibles à toutes les parties prenantes. Cela garantit que tout le monde est sur la même longueur d'onde, réduisant significativement les risques de malentendus.",
        "Collaboration Interactif : Fonctionnalités telles que le partage de documents, les forums de discussion, et les outils de feedback en direct, encouragent une collaboration active et constructive entre les équipes.",
        "Historique des Communications : Un enregistrement complet des communications et décisions permet de suivre l'évolution du projet et de clarifier toute confusion potentielle.",
        ]
        },
        {
          id: 5,
          title: ' Accès Mobile et Flexibilité',
          description: "La gestion des chantiers nécessite souvent une présence sur site, rendant difficile le suivi en temps réel des informations et des mises à jour pour ceux en déplacement ou travaillant à distance.",
          details: '',
          solutions: ["Accessibilité Universelle : 'Vision' est accessible sur tout appareil mobile, permettant aux gestionnaires de projet et aux équipes de rester connectés et informés, quelle que soit leur localisation.",
          "Interface Réactive : Conçu pour une utilisation mobile, l'interface de 'Vision' assure une expérience utilisateur fluide, permettant une interaction facile avec la plateforme même sur des petits écrans.",
          "Gestion en Déplacement : Les utilisateurs peuvent consulter les plans, les rapports, les mises à jour de statut et communiquer avec les équipes sur le chantier en temps réel, offrant une réactivité et une flexibilité accrues dans la prise de décision.",
          "Sécurité des Données : Malgré la flexibilité de l'accès mobile, la sécurité des données n'est pas compromise. 'Vision' utilise des protocoles de sécurité avancés pour protéger les informations sensibles, même en cas d'accès depuis des appareils mobiles."
                ]
        }
    ];

    const toggleDetails = (id) => {
      setActiveProblem(activeProblem === id ? null : id);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { 
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        when: "beforeChildren"
      }
    }
  };
  const problemsContainerVariants = {
    normal: { justifyContent: 'center', opacity: 1, transition: { duration: 0.5 } },
    expanded: { justifyContent: 'flex-start', opacity: 1, transition: { duration: 0.5 } }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -50 },
    visible: { opacity: 1, x: 0 }
  };

  const solutionVariants = {
    hidden: { x: '100%', opacity: 0 },
    visible: { x: 0, opacity: 1 }
  };

  return (
    <motion.div 
        className="problematiques"
        ref={ref}
        variants={containerVariants}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
    >
      <h2>Problématiques du Marché</h2>
      <motion.div 
          className="problems-container"
          variants={problemsContainerVariants}
          initial="normal"
          animate={activeProblem !== null ? "expanded" : "normal"}
          transition={{ duration: 0.5 }}
      >
        <div className="problems">
            {problems.map((problem) => (
                <motion.div 
                    key={problem.id} 
                    className={`problem ${activeProblem === problem.id ? 'active' : ''}`}
                    onClick={() => toggleDetails(problem.id)}
                    variants={itemVariants}
                >
                    <h3>{problem.title}</h3>
                    <p>{problem.description}</p>
                </motion.div>
            ))}
        </div>
                    {activeProblem !== null && (
                        <motion.div 
                            key={activeProblem} // Clé unique basée sur l'ID du problème actif
                            className="solution"
                            variants={solutionVariants}
                            initial="hidden"
                            animate="visible"
                            transition={{ duration: 0.5 }}
                        >
                      <h4>Solution avec Vision:</h4>
                      <ul>
                          {problems.find(p => p.id === activeProblem).solutions.map((advantage, index) => {
                              const parts = advantage.split(/:(.+)/);
                              const title = parts[0] + ":";
                              const description = parts[1];

                              return (
                                  <li key={index}>
                                      <p><strong>{title}</strong>{description}</p>
                                  </li>
                              );
                          })}
                      </ul>
                  </motion.div>
              )}
          </motion.div>
    </motion.div>
  );
};

export default Problematiques;