import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './improvements.scss';

const mediumTermImprovements = [
  { title: "Rapports de Suivi Personnalisés ", comment: "Développement de rapports de suivi pour les conducteurs de travaux, incluant différents statuts comme la première visite de chantier, les visites hebdomadaires, et les visites suite à incidents." },
  { title: "Gestion Documentaire Avancée", comment: "Mise en place d'identifiants uniques pour les clients pour une meilleure gestion des documents légaux (CACES, Kbis, assurance) avec système de rappels pour les dates d'échéance." },
  { title: "Optimisation du Dashboard de Chantier", comment: "Création d'un tableau de bord spécifique à chaque affaire pour un suivi plus précis et ciblé." },
  { title: "Amélioration de la Gestion des Sous-Traitants", comment: "Mise en œuvre d'un planning de disponibilité des sous-traitants en temps réel, facilitant la planification et l'allocation des ressources." },
  { title: "Simplification des Tâches Quotidiennes", comment: "Élaboration d'une liste simplifiée de tâches à réaliser avec des fonctionnalités de rappel pour une meilleure organisation quotidienne." },
  { title: "Rapports de Fin de Chantier", comment: "Automatisation des rapports de fin de travaux pour une documentation complète et systématique à la clôture de chaque chantier." },
];

const longTermImprovements = [
  { title: "Planification Assistée par IA", comment: "Intégration de l'intelligence artificielle pour générer des plannings de chantier, justifiant les choix basés sur des données météorologiques, la disposition des équipes, et d'autres paramètres clés." },
  { title: "Analyse de Données Avancée pour Appels d'Offres", comment: "Développement d'outils d'analyse de données pour répondre aux appels d'offres, en prenant en compte des facteurs historiques et contextuels comme la météo, les prix des années précédentes, et les performances des concurrents." },
  { title: "Prise de Décision Basée sur les Données", comment: "Amélioration des processus décisionnels en s'appuyant sur l'analyse des données historiques et actuelles, pour des choix plus éclairés et stratégiques." },
  { title: "Analyse des Feedbacks Clients et Amélioration Continue", comment: "Utilisation de l'analyse de sentiment et d'autres techniques d'analyse de données pour recueillir et traiter les retours clients, orientant ainsi les améliorations futures du produit." },
  { title: "Automatisation et Prévision dans la Gestion de Matériel", comment: "Création d'un système pour anticiper et automatiser les commandes de matériel en fonction de l'avancement du chantier, optimisant ainsi la gestion des ressources et le fond de roulement." },
  { title: "Gestion Dynamique des Précommandes et Commandes de Matériel", comment: "Développement d'un module intelligent pour la gestion des précommandes et des commandes de matériel, basé sur une analyse précise de l'avancement du chantier et des dates de démarrage planifiées. Ce système permettra une allocation plus efficace des ressources matérielles, en s'assurant que le matériel nécessaire est disponible au bon moment, réduisant ainsi les délais d'attente et optimisant le flux de travail sur le chantier." },
];


const Improvements = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const [activeComment, setActiveComment] = useState(null);

  const handleItemClick = (comment) => {
    setActiveComment(activeComment === comment ? null : comment);
  };

  return (
    <motion.div 
      ref={ref}
      className={`improvements ${inView ? 'animate' : ''}`}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <h2>Axes d'Amélioration et Innovations</h2>
      <div className="improvement">
        <h3>À Moyen Terme</h3>
        <ul>
          {mediumTermImprovements.map((item, index) => (
            <li key={index} onClick={() => handleItemClick(item.comment)}>
              <p>{item.title}</p>
              {activeComment === item.comment && <p className="comment">{item.comment}</p>}
            </li>
          ))}
        </ul>
      </div>
      <div className="improvement">
        <h3>À Long Terme</h3>
        <ul>
          {longTermImprovements.map((item, index) => (
            <li key={index} onClick={() => handleItemClick(item.comment)}>
              <p>{item.title}</p>
              {activeComment === item.comment && <p className="comment">{item.comment}</p>}
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};

export default Improvements;
