import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './business.scss';

const Business = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const containerVariants = {
    hidden: { opacity: 0, y: 50 },
    visible: { opacity: 1, y: 0, transition: { duration: 1 } },
  };

  return (
    <motion.div
      ref={ref}
      className={`business ${inView ? 'animate' : ''}`}
      initial="hidden"
      animate={inView ? 'visible' : 'hidden'}
      variants={containerVariants}
    >
      <h2>Modèle d'Affaires et Évolution</h2>
      <div className="business-content">
        <motion.div className="business-item">
          <h3>Business Model</h3>
          <ul>
            <li>Structure tarifaire</li>
            <li>Fonctionnalités selon les besoins des utilisateurs</li>
            {/* Ajoutez d'autres éléments liés au modèle d'affaires si nécessaire */}
          </ul>
        </motion.div>
        <motion.div className="business-item">
          <h3>Évolution et Déploiement</h3>
          <ul>
            <li>Support client</li>
            <li>Ressources de formation</li>
            <li>Webinaires</li>
            {/* Ajoutez d'autres éléments liés à l'évolution si nécessaire */}
          </ul>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default Business;
