export default function PrivacyPolicy() {
  return (
    <>
      <main>
        <section className="relative overflow-hidden bg-slate-50 -mt-32 pt-40">
          <div className="relative">
            <div className="mx-auto">
              <div className="flex flex-col items-center justify-center pt-16">
                <div className="md:max-w-7xl mx-auto w-full max-w-5xl px-6">
                  <h2 className="font-book font-styling font-display mb-2 text-center text-[3rem] md:text-[3.5rem] tracking-tight leading-[120%] font-gradient">Privacy Policy</h2>
                </div>
              </div>
              <div className="flex flex-col gap-4 py-16 md:max-w-7xl mx-auto w-full max-w-5xl px-6">
                <div className="mx-auto max-w-4xl">
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">1. Introduction</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">Welcome to Vision Team Build</p>
                      <p className="text-base text-slate-11 font-normal">Vision Team Build (“us”, “we”, or “our”) operates <a className="text-primary" target="_blank" href="https://vision-team-build.com" rel="noreferrer">https://vision-team-build.com</a> (hereinafter referred to as <strong className="text-base text-slate-12 font-bold">“Service”</strong>).</p>
                      <p className="text-base text-slate-11 font-normal">Our Privacy Policy governs your visit to <a className="text-primary" target="_blank" href="https://vision-team-build.com" rel="noreferrer">https://vision-team-build.com</a>, and explains how we collect, safeguard and disclose information that results from your use of our Service.</p>
                      <p className="text-base text-slate-11 font-normal">We use your data to provide and improve Service. By using Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
                      <p className="text-base text-slate-11 font-normal">Our Terms and Conditions (“Terms”) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (<strong className="text-base text-slate-12 font-bold">“agreement”</strong>).</p>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">2. Definitions</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">
                        <strong className="text-base text-slate-12 font-bold">SERVICE</strong> means the https://vision-team-build.com website operated by Vision Team Build.</p>
                      <p className="text-base text-slate-11 font-normal">
                        <strong className="text-base text-slate-12 font-bold">PERSONAL DATA</strong> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</p>
                      <p className="text-base text-slate-11 font-normal">
                        <strong className="text-base text-slate-12 font-bold">USAGE DATA</strong> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).</p>
                      <p className="text-base text-slate-11 font-normal">
                        <strong className="text-base text-slate-12 font-bold">COOKIES</strong> are small files stored on your device (computer or mobile device).</p>
                      <p className="text-base text-slate-11 font-normal">
                        <strong className="text-base text-slate-12 font-bold">DATA CONTROLLER</strong> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.</p>
                      <p className="text-base text-slate-11 font-normal">
                        <strong className="text-base text-slate-12 font-bold">DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means any natural or legal person who processes the data on behalf of the Data Controller. We may use the services of various Service Providers in order to process your data more effectively.</p>
                      <p className="text-base text-slate-11 font-normal">
                        <strong className="text-base text-slate-12 font-bold">DATA SUBJECT</strong> is any living individual who is the subject of Personal Data.</p>
                      <p className="text-base text-slate-11 font-normal">
                        <strong className="text-base text-slate-12 font-bold">THE USER</strong> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.</p>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">3. Information Collection and Use</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">We collect several different types of information for various purposes to provide and improve our Service to you.</p>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">4. Types of Data Collected</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <strong className="text-base text-slate-12 font-bold">Personal Data</strong>
                      <p className="text-base text-slate-11 font-normal">While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
                      <ul className="list-inside list-[lower-alpha]">
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">Email address</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">First name and last name</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">Address, State, Province, ZIP/Postal code, City</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">Cookies and Usage Data</span>
                        </li>
                      </ul>
                      <p className="text-base text-slate-11 font-normal">We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link.</p>
                    </div>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <strong className="text-base text-slate-12 font-bold">Usage Data</strong>
                      <p className="text-base text-slate-11 font-normal">We may also collect information that your browser sends whenever you visit our Service or when you access Service by or through a mobile device (“Usage Data”).</p>
                      <p className="text-base text-slate-11 font-normal">This Usage Data may include information such as your computer's Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                      <p className="text-base text-slate-11 font-normal">When you access Service with a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>
                    </div>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <strong className="text-base text-slate-12 font-bold">Tracking Cookies Data</strong>
                      <p className="text-base text-slate-11 font-normal">We use cookies and similar tracking technologies to track the activity on our Service and we hold certain information.</p>
                      <p className="text-base text-slate-11 font-normal">Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyze our Service.</p>
                      <p className="text-base text-slate-11 font-normal">You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
                      <p className="text-base text-slate-11 font-normal">Examples of Cookies we use:</p>
                      <ul className="list-inside list-[lower-alpha]">
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">
                            <strong className="text-base text-slate-12 font-bold">Session Cookies:</strong> We use Session Cookies to operate our Service.</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">
                            <strong className="text-base text-slate-12 font-bold">Preference Cookies:</strong> We use Preference Cookies to remember your preferences and various settings.</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">
                            <strong className="text-base text-slate-12 font-bold">Security Cookies:</strong> We use Security Cookies for security purposes.</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">
                            <strong className="text-base text-slate-12 font-bold">Advertising Cookies:</strong> Advertising Cookies are used to serve you with advertisements that may be relevant to you and your interests.</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">5. Use of Data</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">Vision Team Build uses the collected data for various purposes:</p>
                      <ul className="list-inside list-[lower-alpha]">
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">to notify you about changes to our Service;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">to allow you to participate in interactive features of our Service when you choose to do so;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">to provide customer support; </span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">to gather analysis or valuable information so that we can improve our Service;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">to monitor the usage of our Service;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">to detect, prevent and address technical issues;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">to fulfill any other purpose for which you provide it;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">to provide you with notices about your account and/or subscription, including expiration and renewal notices, email-instructions, etc.;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">to provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">in any other way we may describe when you provide the information;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">for any other purpose with your consent. </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">6. Retention of Data</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">We will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.</p>
                      <p className="text-base text-slate-11 font-normal">We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.</p>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">7. Transfer of Data</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">Your information, including Personal Data, may be transferred to – and maintained on – computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction.</p>
                      <p className="text-base text-slate-11 font-normal">If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.</p>
                      <p className="text-base text-slate-11 font-normal">Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                      <p className="text-base text-slate-11 font-normal">Vision Team Build will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">8. Disclosure of Data</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">We may disclose personal information that we collect, or you provide:</p>
                      <ul className="list-inside list-[lower-alpha]">
                        <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                          <li className="mb-2">
                            <span className="text-base text-slate-11 font-normal">
                              <strong className="text-base text-slate-12 font-bold">Disclosure for Law Enforcement.</strong>
                              <p className="text-base text-slate-11 font-normal">Under certain circumstances, we may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities.</p>
                            </span>
                          </li>
                          <li className="mb-2">
                            <span className="text-base text-slate-11 font-normal">
                              <strong className="text-base text-slate-12 font-bold">Other cases. We may disclose your information also:</strong>
                              <ul className="list-inside list-[lower-alpha]">
                                <li className="mb-2">
                                  <span className="text-base text-slate-11 font-normal">to contractors, service providers, and other third parties we use to support our business;</span>
                                </li>
                                <li className="mb-2">
                                  <span className="text-base text-slate-11 font-normal">to fulfill the purpose for which you provide it;</span>
                                </li>
                                <li className="mb-2">
                                  <span className="text-base text-slate-11 font-normal">with your consent in any other cases;</span>
                                </li>
                              </ul>
                            </span>
                          </li>
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">9. Security of Data</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">10. Your Data Protection Rights Under General Data Protection Regulation (GDPR)</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">If you are a resident of the European Union (EU) and European Economic Area (EEA), you have certain data protection rights, covered by GDPR. – See more at: <a className="text-primary" target="_blank" href="https://eur-lex.europa.eu/eli/reg/2016/679/oj" rel="noreferrer">https://eur-lex.europa.eu/eli/reg/2016/679/oj</a>
                      </p>
                      <p className="text-base text-slate-11 font-normal">We aim to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.</p>
                      <p className="text-base text-slate-11 font-normal">If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please email us at <a className="text-primary" target="_blank" href="mailto:support@vision-team-build.com" rel="noreferrer">support@vision-team-build.com</a>.</p>
                      <p className="text-base text-slate-11 font-normal">In certain circumstances, you have the following data protection rights:</p>
                      <ul className="list-inside list-[lower-alpha]">
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">the right to access, update or to delete the information we have on you;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">the right of rectification. You have the right to have your information rectified if that information is inaccurate or incomplete;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">the right to object. You have the right to object to our processing of your Personal Data;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">the right of restriction. You have the right to request that we restrict the processing of your personal information;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">the right to data portability. You have the right to be provided with a copy of your Personal Data in a structured, machine-readable and commonly used format;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">the right to withdraw consent. You also have the right to withdraw your consent at any time where we rely on your consent to process your personal information;</span>
                        </li>
                      </ul>
                      <p className="text-base text-slate-11 font-normal">Please note that we may ask you to verify your identity before responding to such requests. Please note, we may not able to provide Service without some necessary data.</p>
                      <p className="text-base text-slate-11 font-normal">You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).</p>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">11. Your Data Protection Rights under the California Privacy Protection Act (CalOPPA)</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law’s reach stretches well beyond California to require a person or company in the United States (and conceivable the world) that operates websites collecting personally identifiable information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals with whom it is being shared, and to comply with this policy. – See more at: <a className="text-primary" target="_blank" href="https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/" rel="noreferrer">https://consumercal.org/about-cfc/cfc-education-foundation/california-online-privacy-protection-act-caloppa-3/</a>
                      </p>
                      <p className="text-base text-slate-11 font-normal">According to CalOPPA we agree to the following:</p>
                      <ul className="list-inside list-[lower-alpha]">
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">users can visit our site anonymously;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">our Privacy Policy link includes the word “Privacy”, and can easily be found on the page specified above on the home page of our website;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">users will be notified of any privacy policy changes on our Privacy Policy Page;</span>
                        </li>
                        <li className="mb-2">
                          <span className="text-base text-slate-11 font-normal">users are able to change their personal information by emailing us at <a className="text-primary" target="_blank" href="mailto:support@vision-team-build.com" rel="noreferrer">support@vision-team-build.com</a>.</span>
                        </li>
                      </ul>
                      <p className="text-base text-slate-11 font-normal">Our Policy on “Do Not Track” Signals:</p>
                      <p className="text-base text-slate-11 font-normal">We honor Do Not Track signals and do not track, plant cookies, or use advertising when a Do Not Track browser mechanism is in place. Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.</p>
                      <p className="text-base text-slate-11 font-normal">You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.</p>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">12. Your Data Protection Rights under the California Consumer Privacy Act (CCPA)</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">If you are a California resident, you are entitled to learn what data we collect about you, ask to delete your data and not to sell (share) it. To exercise your data protection rights, you can make certain requests and ask us:</p>
                      <ul className="list-inside list-[lower-alpha]">
                        <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                          <li className="mb-2">
                            <span className="text-base text-slate-11 font-normal">
                              <p className="text-base text-slate-11 font-normal">
                                <strong className="text-base text-slate-12 font-bold">What personal information we have about you.</strong> If you make this request, we will return to you</p>
                              <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                                <ul className="list-inside list-[lower-alpha]">
                                  <li className="mb-2">
                                    <span className="text-base text-slate-11 font-normal">The categories of personal information we have collected about you.</span>
                                  </li>
                                  <li className="mb-2">
                                    <span className="text-base text-slate-11 font-normal">The categories of sources from which we collect your personal information.</span>
                                  </li>
                                  <li className="mb-2">
                                    <span className="text-base text-slate-11 font-normal">The business or commercial purpose for collecting or selling your personal information.</span>
                                  </li>
                                  <li className="mb-2">
                                    <span className="text-base text-slate-11 font-normal">The categories of third parties with whom we share personal information.</span>
                                  </li>
                                  <li className="mb-2">
                                    <span className="text-base text-slate-11 font-normal">The specific pieces of personal information we have collected about you.</span>
                                  </li>
                                  <li className="mb-2">
                                    <span className="text-base text-slate-11 font-normal">A list of categories of personal information that we have sold, along with the category of any other company we sold it to. If we have not sold your personal information, we will inform you of that fact.</span>
                                  </li>
                                  <li className="mb-2">
                                    <span className="text-base text-slate-11 font-normal">A list of categories of personal information that we have disclosed for a business purpose, along with the category of any other company we shared it with.</span>
                                  </li>
                                </ul>
                                <p className="text-base text-slate-11 font-normal">Please note, you are entitled to ask us to provide you with this information up to two times in a rolling twelve-month period. When you make this request, the information provided may be limited to the personal information we collected about you in the previous 12 months.</p>
                              </div>
                            </span>
                          </li>
                          <li className="mb-2">
                            <span className="text-base text-slate-11 font-normal">
                              <strong className="text-base text-slate-12 font-bold">To delete your personal information.</strong> If you make this request, we will delete the personal information we hold about you as of the date of your request from our records and direct any service providers to do the same. In some cases, deletion may be accomplished through de-identification of the information. If you choose to delete your personal information, you may not be able to use certain functions that require your personal information to operate.</span>
                          </li>
                          <li className="mb-2">
                            <span className="text-base text-slate-11 font-normal">
                              <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                                <p className="text-base text-slate-11 font-normal">
                                  <strong className="text-base text-slate-12 font-bold">To stop selling your personal information.</strong> We don't sell or rent your personal information to any third parties for any purpose. You are the only owner of your Personal Data and can request disclosure or deletion at any time.</p>
                                <p className="text-base text-slate-11 font-normal">Please note, if you ask us to delete or stop selling your data, it may impact your experience with us, and you may not be able to participate in certain programs or membership services which require the usage of your personal information to function. But in no circumstances, we will discriminate against you for exercising your rights.</p>
                                <p className="text-base text-slate-11 font-normal">To exercise your California data protection rights described above, please send your request(s) by one of the following means:</p>
                                <p className="text-base text-slate-11 font-normal">By email: <a className="text-primary" target="_blank" href="mailto:support@vision-team-build.com" rel="noreferrer">support@vision-team-build.com</a>
                                </p>
                              </div>
                            </span>
                          </li>
                          <p className="text-base text-slate-11 font-normal">Your data protection rights, described above, are covered by the CCPA, short for the California Consumer Privacy Act. To find out more, visit the official <a className="text-primary" target="_blank" href="http://leginfo.legislature.ca.gov/faces/billTextClient.xhtml?bill_id=201720180AB375" rel="noreferrer">California Legislative Information website</a>. The CCPA took effect on 01/01/2020.</p>
                        </div>
                      </ul>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">13. Service Providers</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">We may employ third party companies and individuals to facilitate our Service (“Service Providers”), provide Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</p>
                      <p className="text-base text-slate-11 font-normal">These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">14. Analytics</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">We may use third-party Service Providers to monitor and analyze the use of our Service.</p>
                      <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                        <strong className="text-base text-slate-12 font-bold">Google Analytics</strong>
                        <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                          <p className="text-base text-slate-11 font-normal">Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualise and personalise the ads of its own advertising network.</p>
                          <p className="text-base text-slate-11 font-normal">For more information on the privacy practices of Google, please visit the Google Privacy Terms web page: <a className="text-primary" target="_blank" href="https://policies.google.com/privacy?hl=en" rel="noreferrer">https://policies.google.com/privacy?hl=en</a>
                          </p>
                          <p className="text-base text-slate-11 font-normal">We also encourage you to review the Google's policy for safeguarding your data: <a className="text-primary" target="_blank" href="https://support.google.com/analytics/answer/6004245" rel="noreferrer">https://support.google.com/analytics/answer/6004245</a>.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">15. CI/CD tools</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">We may use third-party Service Providers to automate the development process of our Service.</p>
                      <div>
                        <strong className="text-base text-slate-12 font-bold">CircleCI</strong>
                        <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                          <p className="text-base text-slate-11 font-normal">CircleCI is provided by Circle Internet Services, Inc.</p>
                          <p className="text-base text-slate-11 font-normal">CircleCI is Continuous Integration, a development practice which is being used by software teams allowing them to build, test and deploy applications easier and quicker on multiple platforms.</p>
                          <p className="text-base text-slate-11 font-normal">For more information on what data Circle CI collects for what purpose and how the protection of the data is ensured, please visit Circle CI Privacy Policy page: <a className="text-primary" target="_blank" href="https://circleci.com/privacy/" rel="noreferrer">https://circleci.com/privacy/</a>.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">16. Payments</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">We may provide paid products and/or services within Service. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
                      <p className="text-base text-slate-11 font-normal">We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
                      <p className="text-base text-slate-11 font-normal">The payment processors we work with are:</p>
                      <div>
                        <strong className="text-base text-slate-12 font-bold">Stripe:</strong>
                        <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                          <p className="text-base text-slate-11 font-normal">Their Privacy Policy can be viewed at: <a className="text-primary" target="_blank" href="https://stripe.com/us/privacy" rel="noreferrer">https://stripe.com/us/privacy</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">17. Links to Other Sites</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">Our Service may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
                      <p className="text-base text-slate-11 font-normal">We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">18. Children's Privacy</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">Our Services are not intended for use by children under the age of 18 (<strong className="text-base text-slate-12 font-bold">“Child”</strong> or <strong className="text-base text-slate-12 font-bold">“Children”</strong>).</p>
                      <p className="text-base text-slate-11 font-normal">We do not knowingly collect personally identifiable information from Children under 18. If you become aware that a Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from Children without verification of parental consent, we take steps to remove that information from our servers.</p>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">19. Changes to This Privacy Policy</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                      <p className="text-base text-slate-11 font-normal">We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update “effective date” at the top of this Privacy Policy.</p>
                      <p className="text-base text-slate-11 font-normal">You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
                    </div>
                  </div>
                  <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                    <h1 className="text-xl tracking-[-0.16px] text-slate-12 font-bold">20. Contact Us</h1>
                    <div className="mb-6 flex flex-col gap-3 text-slate-11 last:mb-0">
                      <p className="text-base text-slate-11 font-normal">If you have any questions about this Privacy Policy, please contact us:</p>
                      <p className="text-base text-slate-11 font-normal">By email: <a className="text-primary" target="_blank" href="mailto:support@vision-team-build.com" rel="noreferrer">support@vision-team-build.com</a>.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}