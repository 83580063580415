import React from 'react';
import { motion } from 'framer-motion';
import './solutions.scss';

const Solutions = () => {
  return (
    <motion.div 
      className="solutions"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <h2>Les Forces Proposées par Vision</h2>
      <div className="problem">
        <h3>Gestion Centralisée des Documents</h3>
        <p>Avantages de la solution standardisée de Vision.</p>
      </div>
      <div className="problem">
        <h3>Suivi en Temps Réel</h3>
        <p>Amélioration de la transparence et de la réactivité.</p>
      </div>
      <div className="problem">
        <h3>Planification Intégrée</h3>
        <p>Optimisation de la coordination des équipes et des ressources.</p>
      </div>
      <div className="problem">
        <h3>Outils pour Visites de Chantier</h3>
        <p>Capacités mobiles pour une gestion améliorée.</p>
      </div>
      <div className="solution-image">
        <img src="visionrectangulaire.png" alt="Solution Vision" />
      </div>
    </motion.div>
  );
};

export default Solutions;
