import React from 'react';
import { motion } from 'framer-motion';
import './introduction.scss';
import { useInView } from 'react-intersection-observer';

const textVariants = {
  initial: {
    x: -500,
    opacity: 0,
  },
  animate: {
    x: 0,
    opacity: 1,
    transition: {
      duration: 1,
      staggerChildren: 0.1,
    },
  },
};

const Introduction = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // L'animation se déclenche une seule fois
    threshold: 0.2, // 20% de la section visible à l'écran pour déclencher l'animation
  });

  return (
    <motion.div
      className="introduction"
      variants={textVariants}
      initial={inView ? "animate" : "initial"}
      animate={inView ? "animate" : "initial"}
      ref={ref}
    >


      <motion.h1 variants={textVariants}>Secteur du Bâtiment en Bref</motion.h1>
      <motion.p variants={textVariants}>
        Le secteur du bâtiment, essentiel à l'économie, emploie plus d'un million de personnes en France.
        Cependant, il est souvent entravé par des retards, des surcoûts et une communication inefficace,
        mettant en évidence un besoin urgent d'innovation.
      </motion.p>
      <motion.h2 variants={textVariants}>Présentation de Vision</motion.h2>
      <motion.p variants={textVariants}>
        "Vision" est une solution SaaS révolutionnaire pour le bâtiment, offrant gestion de projet unifiée,
        planification efficace, suivi en temps réel, et analyses approfondies. Elle transforme les défis en opportunités,
        améliorant la coordination, la prise de décision et la rentabilité.
      </motion.p>
      <motion.img
        src='introduction.png'
        alt="Innovation dans le bâtiment"
        variants={textVariants}
      />
      <motion.h2 variants={textVariants}>
      Tous les chantiers ne se ressemblent pas, mais leur gestion est simplifiée et standardisée par Vision.
      </motion.h2>
    </motion.div>
  );
};

export default Introduction;
