import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import Privacy from './pages/privacy-policy';
import Terms from './pages/terms-of-service';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';


let router = createBrowserRouter([
  {path: "/",element: <App />},
  {path: "/privacy-policy",element: <Privacy />},
  {path: "/terms-of-service",element: <Terms />}
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />;
  </React.StrictMode>
);

