import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import './avantages.scss';

const Avantages = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  return (
    <motion.div
      ref={ref}
      className={`avantages ${inView ? 'animate' : ''}`}
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <h2>Avantages et Impact de Vision</h2>
      <div className="avantage">
        <h3>Communication Améliorée</h3>
        <p>Dans le monde dynamique de la construction, la communication efficace est la clé de voute de tout projet réussi. "Vision" révolutionne la gestion de projet en créant une plateforme unifiée où l'échange d'informations est non seulement instantané mais aussi extrêmement structuré. Cette transformation passe par l'intégration de systèmes de messagerie en temps réel, de tableaux de bord interactifs, et de fonctionnalités de partage de documents qui assurent que chaque membre de l'équipe, des conducteurs de travaux aux sous-traitants, est toujours parfaitement informé et aligné sur les objectifs du projet. En éliminant les barrières traditionnelles de la communication, "Vision" facilite une collaboration transparente, réduit les risques d'erreurs et de malentendus, et augmente considérablement l'efficacité globale de la gestion de projet.</p>
      </div>
      <div className="avantage">
        <h3>Points Clés Réalisés</h3>
        <p>'Vision' se distingue par une suite robuste de fonctionnalités conçues pour répondre aux exigences complexes du secteur du bâtiment. 
          <br />
          Parmi les points clés réalisés, on trouve :</p>
        <ul>
          <li><strong>Gestion Centralisée des Documents :</strong> Stockage et accès sécurisé à tous les documents de projet, assurant la cohérence et la disponibilité des informations.</li>
          <li><strong>Suivi en Temps Réel :</strong> Capacité à surveiller l'état d'avancement des chantiers en temps réel, offrant une visibilité et un contrôle accrus sur chaque étape du projet.</li>
          <li><strong>Planification Intégrée :</strong> Outils avancés pour la planification et la coordination des tâches, garantissant que les ressources sont utilisées de manière optimale.</li>
          <li><strong>Analyse Prédictive :</strong> Utilisation des données historiques et des tendances pour anticiper les besoins futurs, améliorant ainsi la planification et la prise de décision.</li>
          <li><strong>Mobilité et Flexibilité :</strong> Accès mobile complet, permettant une gestion agile des chantiers et une réactivité améliorée, peu importe le lieu.</li>
          <li><strong>Interface Intuitive :</strong> Conception centrée sur l'utilisateur pour une prise en main rapide et une utilisation efficace, minimisant la courbe d'apprentissage.</li>
        </ul>
      </div>
    </motion.div>
  );
};

export default Avantages;
